$(function() {

	$(".js-open-menu").click(function() {
		$(this).toggleClass('open');
		$(this).find('#nav-icon1').toggleClass('open');
		$(this).next().toggleClass('mobile-menu__navmenu--visible');
	});

	$(".js-open-sub-menu").click(function() {
		var $this = $(this);

		if ( $this.is('a') )
		{
			$this = $this.siblings('.mobile-menu__expand-submenu');
		}

		$this.toggleClass('mobile-menu__expand-submenu--open');
		$this.next().toggleClass('mobile-menu__subwrap--visible');

		var $subwrap = $this.next();

		$subwrap.stop().slideToggle(300);

	});

});