$(function() {

    var $sidebarMenu = $('.sidebar-menu');

    if($sidebarMenu.length > 0){
        var $sidebar   = $(".sidebar-menu"),
            $window    = $(window),
            offset     = $sidebar.offset(),
            topPadding = 100;

		var $mainWrap = $('#main-wrapper');

        $window.scroll(updateSidebarPosition);
		updateSidebarPosition();
    }

	function toggleSidebar($submenu)
	{
		var visible = $submenu.is(':visible');

		if ( visible )
		{
			$submenu.slideUp(200);
		}
		else
		{
			$submenu.slideDown(200);
		}
	}

    $sidebarMenu.find('.expand-submenu').click(function (e) {
        toggleSidebar($(this).parent().children('.submenu'));
		$(this).parent().find('.expand-submenu').toggleClass('rotate');
		updateSidebarPosition();
    });

	$sidebarMenu.find('.redirect a').click(function (e) {
		toggleSidebar($(this).parent().children('.submenu'));
		$(this).parent().find('.expand-submenu').toggleClass('rotate');
		updateSidebarPosition();
    });

	function updateSidebarPosition() {
		var s_top = $window.scrollTop();
		$mainWrap.removeClass('sidebar--static-bottom');

		if ( s_top > offset.top - 50) {
			$sidebar.removeClass('sidebar-static');
			$sidebar.addClass('sidebar-fixed');

			var wrapOffset = $mainWrap.offset();

			if (wrapOffset)
			{
				var bottomOffset = $mainWrap.offset().top + $mainWrap.outerHeight();
				var sidebar_height = $sidebar.outerHeight();

				if ( s_top + 50 + sidebar_height >= bottomOffset ) {
					$mainWrap.addClass('sidebar--static-bottom');
				}
			}



		} else if( s_top < offset.top){
			$sidebar.removeClass('sidebar-fixed');
			$sidebar.addClass('sidebar-static');
		}
	}

});