$(function() {

	$('.about-slider__slide-container').each(function( index ) {
		var $ch = $(this).children();
		var $cu = $ch.filter('.about-slider__slide--visible');
		var $n = $cu.next();


		setInterval(function(){
			if($('.about-slider__slide-container').hasClass('loading')){
				return false;
			}

			var $cu = $ch.filter('.about-slider__slide--visible');
			var $n = $cu.next();

			if(!$n.length){
				$n = $ch.first();
			}

			$('.area-slide__container').addClass('loading');

			slideOut($cu, 1, function(){
				slideIn($n, 1);
			});

		}, 5000 );
	});




	function slideIn($slide, direction) {

		if(direction == 0){

			$slide.addClass('about-slider__slide--fadeout');
			$slide.removeClass('noanim');

			$slide.addClass('about-slider__slide--visible');

			setTimeout(function(){
				$slide.removeClass('about-slider__slide--fadeout');
			}, 3000 );

		}else if(direction == 1) {

			$slide.addClass('about-slider__slide--fadein');
			$slide.removeClass('noanim');

			$slide.addClass('about-slider__slide--visible');

			setTimeout(function(){
				$slide.removeClass('about-slider__slide--fadein');
			}, 3000 );
		}

		setTimeout(function(){
			$('.about-slider__slide-container').removeClass('loading');
		}, 700);
	}



	function slideOut($slide, direction, callback) {

		setTimeout(function(){
			$slide.removeClass('about-slider__slide--visible');
			callback();
		}, 700);
	}

});

//Optimized
// $(function() {

// 	var $ch = $('.about-slider__slide-container').children();
// 	var $cu = $ch.filter('.about-slider__slide--visible');
// 	var $n = $cu.next();

// 	setInterval(function(){
// 		if($('.about-slider__slide-container').hasClass('loading')){
// 			return false;
// 		}

// 		$('.area-slide__container').addClass('loading');
// 		slideOut($cu, 1, function(){
// 			if(!$n.length){
// 				$n = $ch.first();
// 			}
// 			slideIn($n, 1);
// 		});

// 	}, 5000 );


// 	function slideIn($slide, direction) {
// 		if(direction == 0){
// 			$slide.addClass('about-slider__slide--fadeout about-slider__slide--visible noanim');
// 			setTimeout(function(){
// 				$slide.removeClass('about-slider__slide--fadeout');
// 			}, 3000 );
// 		}else if(direction == 1) {
// 			$slide.addClass('about-slider__slide--fadein about-slider__slide--visible noanim');
// 			setTimeout(function(){
// 				$slide.removeClass('about-slider__slide--fadein');
// 			}, 3000 );
// 		}

// 		setTimeout(function(){
// 			$('.about-slider__slide-container').removeClass('loading');
// 		}, 700);
// 	}



// 	function slideOut($slide, direction, callback) {
// 		setTimeout(function(){
// 			$slide.removeClass('about-slider__slide--visible');
// 			callback();
// 		}, 700);
// 	}

// });