// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
window.$ = require('jquery');
window.jQuery = window.$;
require('swiper');
require('jquery.transit');

window.inlineEdit = require('./inline-edit.js');

$(function() {

	$('.js-gallery-item').on('click', function(){
		$('body').css('overflow', 'hidden');
		var imageLink = $(this).data('link');

		$('.lightbox').addClass('lightbox--visible');
		$('.js-lightbox-image').attr('src', imageLink);
	});

	$('.js-close-lightbox, .lightbox').on('click', function(){
		$('body').css('overflow', 'visible');
		$('.lightbox').removeClass('lightbox--visible');
		$('.js-lightbox-image').attr('src', '');
	});

	var swiper = new Swiper('.swiper-container', {
	    pagination: '.swiper-pagination',
	    paginationClickable: true,
	    autoplay: 5000,
	    spaceBetween: 30,
	    loop: true
	});

	$( ".js-toggle-accordion" ).click(function() {
		if (!$(this).hasClass('active')) {
			var accordionTitle = $(this).text();
			_paq.push(["trackEvent", "click_faq", "click_faq", accordionTitle]);
		}
		$(this).parent().parent().parent().toggleClass('accordion__item--active');
		$(this).toggleClass('active');
	});

	$( ".js-show-more-accordions" ).click(function() {
		var $el = $(this).parent().find('.accordion__more-items');
		$el.toggleClass('accordion__more-items--active');
		$(this).toggleClass('accordion__show-more--active');
		$(this).html('Stäng');

		if(!$el.hasClass('accordion__more-items--active')){
			$(this).html('Visa fler');
		}else{
			$(this).html('Stäng');
		}
	});

	$( ".js-show-more-files" ).click(function() {
		var $el = $(this).parent().find('.article-files__more');
		$el.toggleClass('article-files__more--visible');
		$(this).toggleClass('accordion__show-more--active');
		$(this).html('Stäng');

		if(!$el.hasClass('article-files__more--visible')){
			$(this).html('Visa fler');
		}else{
			$(this).html('Stäng');
		}
	});


	$( ".js-plain-listing" ).click(function() {
		var $el = $(this).parent().find('.plain-article-listing__more');
		$el.toggleClass('plain-article-listing__more--active');
		$(this).toggleClass('plain-article-listing__show-more--active');
		$(this).html('Stäng');

		if(!$el.hasClass('plain-article-listing__more--active')){
			$(this).html('Visa fler');
		}else{
			$(this).html('Stäng');
		}
	});

	$('[name=target_group]').change(function () {

		var currentValue = $(this).val();

		$('[name=target_group]').each(function () {

			var value = $(this).val();

			var $select = $('[name=message_type-' + value + ']');

			if ( value == currentValue )
			{
				$select.show();
			}
			else
			{
				$select.hide();
			}

		});

	}).filter(':checked').change();

	window.inlineEdit.setupDom();

	var $scrollTarget = $('.js-scroll-here').eq(0);

	if ( $scrollTarget.length )
	{
		$('html, body').scrollTop($scrollTarget.offset().top);
	}

	$('.js-animate-scroll').click(function (e) {

		var $target = $($(this).attr('href')).eq(0);

		if ( $target.length )
		{
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $target.offset().top
			}, 500);
		}
	});

});

const alert_message = document.querySelector('.js-alert-message');
if(!sessionStorage.getItem('display_alert_message') && alert_message){
	alert_message.classList.add('visible');
}

const close_alert_button = document.querySelector('.js-close-alert-message');
if (close_alert_button) {
	close_alert_button.addEventListener('click', function(){
		alert_message.classList.remove('visible');
		sessionStorage.setItem('display_alert_message', false);
	});
}
