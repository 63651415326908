module.exports = new function() {

	function openPopup(url) {
		$.magnificPopup.open({
			closeOnBgClick: false,
			items: {
				src: url
			},

			type: 'iframe',
			callbacks: {
				close: function () {
				}
			}
		})
	}

	return {

		setupDom: function () {

			$('body').on('click', '.admin-inline-link', function (e) {
				e.preventDefault();

				var $this = $(this);

				openPopup($this.prop('href'))
			});
		},

		setContent: function (id, content) {

			$('#' + id).find('.admin-inline-edit--content').html(content);

		},

		updateAndClose: function (data) {

			this.setContent(data.id, data.content);
			this.close();
		},

		close: function () {

			$.magnificPopup.close();

		}

	}

};
